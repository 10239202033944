@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGE STYLES */
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-phone-number-input/style.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff");
}

@font-face {
  font-family: "Sunflower-Bold";
  src: url("../assets/fonts/Sunflower-Bold.ttf");
}

/* FONTS */
@font-face {
  font-family: "IBM-Bold";
  src: url("../assets/fonts/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBM-Semibold";
  src: url("../assets/fonts/IBMPlexSans-SemiBold.ttf");
}

@font-face {
  font-family: "IBM-Regular";
  src: url("../assets/fonts/IBMPlexSans-Regular.ttf");
}

.container-full-width {
  max-width: 100%;
}

.container {
  max-width: 150vh !important;
  margin: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* RANGE PICKER */
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  border-top-left-radius: 1vh;
  border-bottom-left-radius: 1vh;
}

.rdrDateRangePickerWrapper
  .rdrDefinedRangesWrapper
  .rdrStaticRanges
  .rdrStaticRange {
  border-top-left-radius: 1vh;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper {
  border-top-right-radius: 1vh;
  border-bottom-right-radius: 1vh;
}

.rdrDateRangePickerWrapper .rdrDateDisplayWrapper {
  border-top-right-radius: 1vh;
}

/* TRADES TABLE */
.data-table {
  border: 1px solid #e5e5e5;
  border-radius: 8px !important;
}

.data-table .rdt_Table {
  border-radius: 8px;
}

.data-table .rdt_TableHead {
  font-family: "IBM-Semibold", sans-serif !important;
  border-top-left-radius: 8px;
}

.data-table .rdt_TableHeadRow {
  min-height: 5vh;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 8px;
}

.data-table .rdt_TableHeadRow .rdt_TableCol {
  font-size: 1.5vh;
}

.data-table .rdt_TableHeadRow .rdt_TableCol:first-child {
  border-top-left-radius: 8px;
}

.data-table .rdt_Table .rdt_TableBody .rdt_TableCell {
  padding: 1.5vh;
}

.data-table .rdt_Table .rdt_TableBody .rdt_TableCell > div {
  text-overflow: clip;
  white-space: wrap;
  overflow: visible;
}

/* TABLE PAGINATION */
.rdt_Pagination {
  margin-top: 2.1vh;
  font-family: "IBM-Semibold", sans-serif !important;
  background-color: white !important;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  min-height: 5vh !important;
}

/* REACT SELECT */
.dropdown__control {
  border: 1px solid #e5e5e5 !important;
  border-radius: 0.5rem !important;
  height: 5vh;
}

.dropdown__control .dropdown__value-container .dropdown__placeholder {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown__control .dropdown__value-container .dropdown__single-value {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown__control .dropdown__value-container .dropdown__multi-value__label {
  font-family: "IBM-Semibold", sans-serif;
  font-size: 1.5vh;
}

.dropdown__control .dropdown__indicators .dropdown__indicator-separator {
  display: none;
}

.dropdown__menu {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown__menu .dropdown__menu-list .dropdown__option--is-focused {
  background-color: transparent;
}

.dropdown__menu .dropdown__menu-list .dropdown__option--is-selected {
  background-color: #f3cd16;
}

.dropdown__menu .dropdown__menu-list .dropdown__option--is-selected:hover {
  background-color: #f3cd16 !important;
}

.dropdown__menu .dropdown__menu-list .dropdown__option:hover {
  background-color: #f5f5f5;
}

.bg-banner {
  height: 85vh;
  background-image: url("../assets/images/CMS/hero-banner.jpg");
  background-size: cover;
  border-radius: 16px;
}

/* REACT SELECT ERROR */
.dropdown_error__control {
  border: 1px solid rgb(244, 67, 54) !important;
  border-radius: 0.5rem !important;
  height: 5vh;
}

.dropdown_error__control
  .dropdown_error__value-container
  .dropdown_error__placeholder {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown_error__control
  .dropdown_error__value-container
  .dropdown_error__single-value {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown_error__control
  .dropdown_error__value-container
  .dropdown_error__multi-value__label {
  font-family: "IBM-Semibold", sans-serif;
  font-size: 1.5vh;
}

.dropdown_error__control
  .dropdown_error__indicators
  .dropdown_error__indicator-separator {
  display: none;
}

.dropdown_error__menu {
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
}

.dropdown_error__menu
  .dropdown_error__menu-list
  .dropdown_error__option--is-focused {
  background-color: transparent;
}

.dropdown_error__menu
  .dropdown_error__menu-list
  .dropdown_error__option--is-selected {
  background-color: #f3cd16;
}

.dropdown_error__menu
  .dropdown_error__menu-list
  .dropdown_error__option--is-selected:hover {
  background-color: #f3cd16 !important;
}

.dropdown_error__menu .dropdown_error__menu-list .dropdown_error__option:hover {
  background-color: #f5f5f5;
}

/* HIDE THE ARROWS IN NUMBER INPUT */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* TEXT EDITOR */
.rdw-editor-wrapper {
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  font-family: "IBM-Semibold", sans-serif;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
  border: 0px solid transparent;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rdw-editor-wrapper .rdw-editor-main {
  height: 10vh;
  padding: 1vh;
}

.rdw-editor-wrapper .rdw-editor-main::-webkit-scrollbar {
  display: none;
}

.rdw-editor-wrapper .rdw-editor-main .public-DraftEditorPlaceholder-inner {
  font-family: "IBM-Regular", sans-serif !important;
  font-size: 1.5vh;
}

.rdw-editor-wrapper .rdw-editor-main .public-DraftStyleDefault-ltr {
  margin: 0px;
}

/* REACT CALENDAR */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 1.3vh 2.1vh;
  font-family: "IBM-Regular", sans-serif;
  font-size: 1.5vh;
  width: 100%;
}

.react-datepicker__input-container input:focus {
  outline: none;
  width: 100%;
}

.react-datepicker {
  font-family: "IBM-Regular", sans-serif !important;
}

.backdrop-blur-sm {
  backdrop-filter: blur(0px);
}

.detail-tab {
  justify-content: flex-start;
}

.detail-tab > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-tab > div > div {
  text-align: left;
}

.spline-chart .apexcharts-canvas {
  height: 35vh !important;
}

.spline-chart > div {
  min-height: auto !important;
  height: 30vh !important;
}

.spline-chart .apexcharts-canvas svg {
  height: 30vh !important;
}

.spline-chart .apexcharts-canvas foreignObject {
  height: 30vh !important;
}

.pie-chart .apexcharts-canvas {
  width: 100% !important;
  height: 30vh !important;
}

.pie-chart > div {
  min-height: auto !important;
  height: 30vh !important;
}

.pie-chart .apexcharts-canvas svg {
  width: 100% !important;
  height: 30vh !important;
}

.pie-chart .apexcharts-canvas foreignObject {
  width: 100% !important;
  height: 30vh !important;
}

/* RESPONSIVE */
@media (max-width: 575px) {
  .container-full-width {
    padding: 1vh;
  }

  .container {
    padding: 0vh 1.5vh;
  }
}

.lists-pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: center;
}

.lists-pagination .previous,
.lists-pagination .next {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px 10px;
  border: 1px solid #e5e5e5;
}

.lists-pagination .previous a,
.lists-pagination .next a {
  text-decoration: none;
  color: #f3d543;
}

.lists-pagination .list-pagination-btn {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.lists-pagination li {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.lists-pagination li a {
  font-size: 14px;
  font-weight: 600;
}

.lists-pagination li:hover {
  background-color: #f3d543;
}

.lists-pagination li:hover a {
  color: #fff;
}

.lists-pagination .active {
  background-color: #f3d543;
}

.lists-pagination .active a {
  color: #fff;
}

.toastContainer {
  font-family: "IBM-Semibold", sans-serif !important;
}

/* PHONE INPUT STYLES */
.error.PhoneInput .PhoneInputInput {
  border: 1px solid rgba(229, 115, 115, 1);
}

.PhoneInputInput {
  font-family: "IBM-Regular", sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 0.9vh 1.7vh;
  font-size: 1.6vh;
}

.PhoneInputInput::placeholder {
  font-family: "IBM-Regular", sans-serif;
}

.PhoneInputCountry {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 1vh;
}

/* DATATABLE */
/* DATATABLE */
.custom-datatable .rdt_TableHeadRow {
  min-height: 5vh !important;
  border-bottom: 0px solid transparent;
  border-bottom-width: 0px !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.custom-datatable .rdt_TableCol {
  font-size: 1vh !important;
  font-family: "IBM-Semibold", sans-serif;
  color: #777;
  border: 1px solid #e5e5e5;
  border-right: 0px solid transparent;
}

.custom-datatable .rdt_TableCol div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.custom-datatable .rdt_TableCol:first-child {
  border-top-left-radius: 0.5rem !important;
}

.custom-datatable .rdt_TableCol:last-child {
  border-top-right-radius: 0.5rem !important;
  border-right: 1px solid #e5e5e5 !important;
}

.custom-datatable .rdt_TableRow {
  min-height: 5vh !important;
  border-bottom: 0px solid transparent;
  border-bottom-width: 0px !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.custom-datatable .rdt_TableCell {
  font-size: 1vh !important;
  font-family: "IBM-Semibold", sans-serif;
  color: #777;
  border: 1px solid #e5e5e5;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
}

.custom-datatable .rdt_TableCell div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.custom-datatable .rdt_TableCell:last-child {
  border-right: 1px solid #e5e5e5 !important;
}

.custom-datatable .rdt_TableRow:last-child .rdt_TableCell:first-child {
  border-bottom-left-radius: 0.5rem !important;
}

.custom-datatable .rdt_TableRow:last-child .rdt_TableCell:last-child {
  border-bottom-right-radius: 0.5rem !important;
}

.Toastify__toast-body {
  font-family: "IBM-Semibold", sans-serif;
  font-size: 1.7vh;
}

.rdt_TableCol {
  font-family: "IBM-Semibold", sans-serif;
}
